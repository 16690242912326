@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
body, html {
  margin: 0;
  padding: 0;
}
body{
  min-height: 100vh;
  background: #F7F7F7;
}
* {
  box-sizing: border-box;
}
